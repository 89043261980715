
  import { mapState } from 'vuex'
  import ScreeningsConfiguration from '~/components/settings/screeningsConfiguration.vue'
  import MyAlerts from '~/components/settings/myAlerts.vue'

  export default {
    name: `AlertsSettings`,
    components: { ScreeningsConfiguration, MyAlerts },
    props: {
      value: {
        type: Object,
        default: null
      },
      update: {
        type: String,
        default: `default_dossiers_options`
      },
      withAlertSwitch: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState({
        dossierSettings: (state) => JSON.parse(JSON.stringify(state.settings.me))
      })
    },
    methods: {
      async changeAlert(ev) {
        await this.$store.commit(`settings/SET_VALUE`, {
          namespace: this.update,
          value: JSON.parse(JSON.stringify(ev))
        })
      },
      setErrors(err) {
        Object.entries(err).forEach(([key, errors]) => {
          if(this.update === `personal_dossiers_settings`){
            this.$refs.myAlerts.setErrors(err);
          }else {
            this.$refs.screeningsConfiguration.setErrors(err);
          }
        });
      }
    }
  }
